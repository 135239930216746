.mainContainer {
  background-color: #fff !important;
}

.hero {
  background-image: url("../../assets/images/hero.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 462px;
  position: relative;
}
.hero .headerTextContainer {
  position: absolute;
  bottom: 0;
}
.hero .headerText {
  background-color: #fff;
  color: #7b4fbd;
}
.hero .headerText h1 {
  font-size: 300%;
  font-weight: bold;
}

.paragraphText {
  text-align: justify;
  padding: 20px;
  width: 100%;
  background-color: #fff !important;
}
.paragraphText span {
  font-weight: bold;
}
.paragraphText .imageWrapper {
  text-align: center;
}
.paragraphText img.left {
  padding-right: 20px;
  float: left;
}
.paragraphText img.right {
  padding-left: 20px;
  float: right;
}
.paragraphText img.center {
  margin: auto;
}

.graphImage {
  background-image: url("../../assets/images/graph.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
}

.line {
  height: 5px;
  background-color: #bdbdbd;
}

h2 {
  font-size: 20px !important;
}

.footer {
  background-image: url("../../assets/images/footer-bar.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 50px;
  color: #fff;
  text-align: center;
}
.footer a {
  color: #fff;
  text-decoration: none;
}
.footer a :visited {
  color: #fff;
}/*# sourceMappingURL=FinancialAcumenELearningFlyer.component.css.map */